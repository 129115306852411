<template>
  <div class="home">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-offset-3 payment-buttons">
          <h4>Payment Overdue</h4>
          <button class="button" v-on:click="openBillingPortal">Update Card</button>
          <button class="button logout" v-on:click="logout">Log Out</button>
        </div>
       
      </div>
    </div>
  </div>
</template>
 
<script>
import { billingService } from "@/services";
export default {
  components: {
    
  },
  data: () => ({
    first: null,
    last: null,

  }),
  created() {

  },
  methods: {
    login() {
      this.$auth.loginWithRedirect();
    },
    logout() {
      //Not sure why this doesnt work on mobile
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
    openBillingPortal() {
      billingService
        .getPortal()
        .then((data) => {
          console.log(data);
          window.open(data, "_blank");
        })
        .catch((error) => {
          this.$swal("Error", error.response.data);
          this.overlay = false;
        });
    },
  },
};
</script> 
<style scoped>
.logout {
  margin-left: 15px;
  background-color: #f19953;
  border-color: #f19953;
}
.cancel {
  margin-left: 15px;
  background-color: #ed6a5e;
  border-color: #ed6a5e;
}
.button-row {
  margin-bottom: 25px;
}
.payment-buttons {
margin-bottom: 25px;
margin-top:25px;
}
</style>